<script setup lang="ts">
import { RouterView } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import { onMounted } from 'vue';
import { utmInit } from '@/helper/helper';

onMounted(async () => {
  utmInit();
});
</script>

<template>
  <app-layout>
    <router-view />
    <notifications position="bottom center" />
  </app-layout>
</template>

<style scoped lang="scss"></style>
