function getMainDomain(url: string): string {
  url = url.replace(/(^\w+:|^)\/\//, '');
  const slashIndex = url.indexOf('/');
  if (slashIndex !== -1) {
    url = url.substring(0, slashIndex);
  }
  const domainParts = url.split('.');
  if (domainParts.length > 2) {
    url =
      domainParts[domainParts.length - 2] +
      '.' +
      domainParts[domainParts.length - 1];
  }

  return url;
}

interface CookieOptions {
  path?: string;
  expires?: Date | string;
  domain?: string;
  secure?: boolean;
  'max-age'?: number;
  samesite?: 'strict' | 'lax' | 'none';
  [key: string]: any;
}
interface SessionParams {
  landingPage?: string;
  referrerPage?: string;
  source?: string;
  medium?: string;
  campaign?: string;
  fbclid?: string;
  gclid?: string;
  term?: string;
  content?: string;
  visitor_id?: string;
  ga_referrer?: string;
  ga_landing_page?: string;
}
export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\\.$?*|{}()[\]\\/+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
export function parseQueryString(query: string): SessionParams {
  const params: SessionParams = {};
  const regex = /([^;=]+)=([^;]*)/g;
  let match;

  while ((match = regex.exec(query)) !== null) {
    const key = match[1].trim() as keyof SessionParams;
    const value = match[2] ? decodeURIComponent(match[2]) : '';
    params[key] = value;
  }

  return params;
}
export function setCookie(
  name: string,
  value: string,
  options: CookieOptions = {},
): void {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}
export function deleteCookie(name: string): void {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}
export function capitalizeEachWord(str: string): string {
  if (!str) return '';
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function utmInit() {
  const a = getCookie('firstSession');
  const b = getCookie('returnedSession');
  const c = getCookie('cdmaSessionCount');

  if (!a) {
    const d = doLogic();
    setCookie('firstSession', d, {
      domain: `.${getMainDomain(document.location.hostname)}`,
    });
  }
  if (!b) {
    const d = doLogic();
    setCookie('returnedSession', d, {
      domain: `.${getMainDomain(document.location.hostname)}`,
    });
    setCookie('cdmaSessionCount', String(Number(c) + 1), {
      domain: `.${getMainDomain(document.location.hostname)}`,
    });
  }
}

function doLogic(): string {
  const landingPage = document.location.href;
  const queries = document.location.search.trim().slice(1);
  const referrer = document.referrer;
  const params: { [key: string]: string | undefined } = {};
  const searchEngines = ['bing', 'google', 'yahoo', 'baidu', 'yandex', 'ask'];
  let source: string | undefined,
    medium: string | undefined,
    campaign: string | undefined,
    gclid: string | undefined,
    fbclid: string | undefined,
    term: string | undefined,
    content: string | undefined;

  for (const keyValue of queries.split('&')) {
    const [key, value] = keyValue.split('=');
    params[key] = value;
  }

  source = params.utm_source;
  medium = params.utm_medium;
  campaign = params.utm_campaign;
  fbclid = params.fbclid;
  term = params.utm_term;
  content = params.utm_content;
  if (params.gclid) {
    gclid = params.gclid;
    source = source ? source : 'google';
    medium = medium ? medium : 'cpc';
    campaign = campaign ? campaign : '(not set)';
  }
  if (!source) {
    if (referrer) {
      const matchingEngine = searchEngines.find((engine) =>
        referrer.includes(engine),
      );
      if (matchingEngine) {
        source = matchingEngine;
        medium = 'organic';
      } else {
        source = referrer;
        medium = 'referral';
      }
    } else {
      source = 'direct';
      medium = '(none)';
      campaign = '(not set)';
    }
  }

  return `landingPage=${landingPage};referrerPage=${referrer};source=${source};medium=${medium};campaign=${campaign};fbclid=${fbclid};gclid=${gclid};term=${term};content=${content};`;
}
