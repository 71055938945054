import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { researchStatus } from '@/types/ResearchStatus';
import type { Research } from '@/domain/Research';

export const useResearch = defineStore('research', () => {
  const research = ref<Research>();

  const status = ref<researchStatus | null>(null);

  const mockStarted = ref<boolean>(false);

  const mockComplited = ref<boolean>(false);

  const isCompleted = ref<boolean>(false);

  const isAlreadyDone = ref<boolean>(false);

  const isRedirectedFromDashboard = ref<boolean>(false);

  const isShowAnimation = ref<boolean>(false);

  const stage = ref<number>(0);

  const stageTotal = ref<number>(0);

  return {
    status,
    mockStarted,
    mockComplited,
    isAlreadyDone,
    research,
    isCompleted,
    isShowAnimation,
    stage,
    stageTotal,
    isRedirectedFromDashboard,
  };
});
