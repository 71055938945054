import { ref } from 'vue';
import { defineStore } from 'pinia';
import type addressInformationType from '@/types/AddressInformationType';
import type selectType from '@/types/SelectType';
import type practiceAreaType from '@/types/PracticeAreaType';
import type posibilityCheckType from '@/types/PosibilityCheckType';

export const useCompareFirmStore = defineStore('compareFirm', () => {
  const addressInformation = ref<addressInformationType | null>(null);

  const selectedPracticeArea = ref<selectType | null>(null);

  const selectedNumberOfAttorneys = ref<number>(0);

  const manuallyWebsiteUrl = ref<string>('');

  const allPracticeAreas = ref<Array<practiceAreaType>>([]);

  const posibilityCheck = ref<Array<posibilityCheckType>>([]);

  const step = ref<1 | 2>(1);

  function clear() {
    addressInformation.value = null;
    selectedPracticeArea.value = null;
    selectedNumberOfAttorneys.value = 0;
    allPracticeAreas.value = [];
    posibilityCheck.value = [];
    step.value = 1;
  }

  return {
    addressInformation,
    selectedPracticeArea,
    selectedNumberOfAttorneys,
    allPracticeAreas,
    posibilityCheck,
    step,
    manuallyWebsiteUrl,
    clear,
  };
});
