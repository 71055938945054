import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { uuid } from 'vue-uuid';
import App from './App.vue';
import router from './router';
import { vMaska } from 'maska';
import Notifications from '@kyvg/vue3-notification';
import GoogleTagManager from '@/services/GoogleTagManager';
import './assets/styles/main.scss';
import * as Sentry from '@sentry/vue';
import VueTippy from 'vue-tippy';
import VueSnip from 'vue-snip';

GoogleTagManager();

const token = uuid.v4();

const app = createApp(App).directive('maska', vMaska);

export const socketHost = import.meta.env.VITE_WS_URL + '/ws?token=' + token;

export const socket = new WebSocket(socketHost);

export const socketPassword = token;

app.use(createPinia());
app.use(router);
app.use(Notifications);
app.use(VueSnip);
app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy',
  },
);

Sentry.init({
  app,
  environment:
    import.meta.env.VITE_API_ENV == 'prod' ? 'production' : 'development',
  dsn: 'https://0689627be4c52a94a7a3194402109a72@o497041.ingest.sentry.io/4505713883283456',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://compare.growlawfirm.com/',
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ['Non-Error promise rejection captured'],
});

app.mount('#app');
