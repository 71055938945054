import axios from 'axios';
import type practiceAreaType from '@/types/PracticeAreaType';
import type { requestPosibilityCheck } from '@/types/RequestPosibilityCheck';
import type startResearchDataType from '@/types/StartResearchDataType';
import { socketPassword } from '@/main';
import type contactInformationType from '@/types/ContactInformationType';
import type {
  FullResponseResearchType,
  ResearchType,
} from '@/types/ResearchType';
import httpErrorHandler from '@/services/HttpErrorHandler';

const apiHost = import.meta.env.VITE_API_URL;

const httpClient = axios.create({
  baseURL: apiHost,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

export const getAddressInfomation = async (place_id: string) => {
  return await httpClient
    .get('/api/objects/get-address-information?place_id=' + place_id)
    .catch(httpErrorHandler);
};

export const getPracticeAreas = async () => {
  return await httpClient
    .get<Array<practiceAreaType>>('/api/practice-areas/all')
    .catch(httpErrorHandler);
};

export const fetchPosibilityCheck = async (data: requestPosibilityCheck) => {
  return await httpClient
    .post('/api/keywords/posibility-check', data)
    .catch(httpErrorHandler);
};

export const startResearches = async (data: startResearchDataType) => {
  return await httpClient
    .post<FullResponseResearchType>(`/api/researches`, data, {
      headers: {
        token: socketPassword,
      },
    })
    .catch(httpErrorHandler);
};

export const getResearch = async (_id: string) => {
  return await httpClient
    .get<ResearchType>('/api/researches/' + _id)
    .catch(httpErrorHandler);
};

export const saveContactInformation = async (
  research_id: string,
  data: contactInformationType,
) => {
  return await httpClient
    .put(`/api/researches/${research_id}`, data)
    .catch(httpErrorHandler);
};
